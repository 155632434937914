import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async save(id, data) {
    let result = await axios.post(`${API_ROUTES.registerAccompanist.save}`, data);

    return result;
  },

  async search(identification, enterpriseUuid, departure_date) {
    let result = await axios.get(`${API_ROUTES.registerAccompanist.get}/${identification}/${enterpriseUuid}/${departure_date}`);

    return result;
  },

}